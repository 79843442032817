.users-page {
  display: grid;
  grid-template-columns: 250px auto;

  aside.sidebar {
    a {
      i {
        margin-right: 10px;
      }

      color: #ffffff;
      font-size: 16px;
    }
  }

  .inner {
    display: block;
    padding: 15px;
    flex-grow: 1;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid #dddddd;
  padding: 15px;

  .left-side {
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0;
    }
  }

  .right-side {
    .tag-filter {
      margin-right: 10px;
    }
  }

  button {
    margin-left: 10px!important;
  }
}

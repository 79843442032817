.conversation-tags {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .header label {
    font-weight: 600;
    font-size: 16px;
    opacity: 0.5;
  }

  .header i.icon {
    color: #afafaf;
    font-size: 16px;
    cursor: pointer;
  }

  .tag-item {
    margin-bottom: 5px;
    word-break: break-all;
  }
}

.modal-conversation-tags-select {
  .ui.icon.header  {
    text-align: left;
  }

  .content {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 40px;
  }
}

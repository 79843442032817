.dashboard-page {
  display: grid;
  grid-template-columns: 175px auto;

  aside.sidebar {
    h2.section-title {
      margin-bottom: 0px;
    }

    ul.sms-message-list {
      list-style-type: none;
      padding: 0;

      li {
        cursor: pointer;
        border-bottom: 1px solid #7482c6;
        padding: 14px 0;

        .phone-number {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            margin-right: 10px;
          }
        }

        .ui.placeholder,
        .ui.placeholder .image.header:after,
        .ui.placeholder .line,
        .ui.placeholder .line:after,
        .ui.placeholder>:before {
          background-color: #4b60cb;
        }
      }

      li.active {
        background: #E07B39!important;
      }

      li.unread {
        .phone-number {
          font-weight: 900;
        }
      }
    }
  }

  main {
    height: 100vh;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .phone-number-search-filter {
      margin-right: 5px;
    }
  }
}

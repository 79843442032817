.conversation {
  display: flex;

  .ui.message.inbound {
    background: #f8f8f9;
  }

  .ui.message.outbound {
    background: #4b60cb;
    color: #ffffff;
  }

  .ui.message .msg-time {
    opacity: 0.3;
    text-align: right;
    font-size: 12px;
  }

  .message-bar-wrap {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    padding: 15px 0;
    bottom: 0;
    background-color: #FFFFFF;
    margin-left: 15px;
    margin-right: 15px;

    .input-set {
      display: flex;
      justify-content: space-between;
      border: 1px solid #dddddd;
      border-radius: 3px;
      padding: 10px 5px;

      input[type="text"] {
        flex-grow: 1;
        border: none;
        padding-left: 5px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
